import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import useStyles from './styles';

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { plan } = formValues;

  const newPlan = JSON.parse(plan);
  const { users, connections, price } = newPlan;

  const generatePurchaseCode = () => {
    // Generate a unique purchase code here, e.g., using a timestamp or UUID
    const purchaseCode = generateUniqueCode();
    
    // Construct a more friendly and informative message for WhatsApp
    const message = `*Hola*, te envío mi pago de suscripción mensual. Detalles:\n\n- *Colaboradores*: ${users}\n- *Número de WhatsApps*: ${connections}\n- *Método de Pago*: Mensual\n- *Total a Pagar*: COP ${price.toLocaleString('pt-br', { minimumFractionDigits: 2 })}\n- *Código de Compra*: ${purchaseCode}\n\nGracias por tu atención.`;

    // Replace '573005133221' with the actual WhatsApp number
    const whatsappNumber = '573005133221';
    
    // Generate a WhatsApp URL
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
    
    // Open WhatsApp in a new tab with the message
    window.open(whatsappUrl);
  };

  const generateUniqueCode = () => {
    // You can generate a unique purchase code here, e.g., using a timestamp or UUID
    const timestamp = Date.now();
    const uniqueCode = `PURCHASE-${timestamp}`;
    return uniqueCode;
  };

  return (
    <Grid container direction="column" alignItems="center" xs={12} sm={12}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Enviar Comprobante
      </Typography>
      <Typography gutterBottom>Colaboradores: {users}</Typography>
      <Typography gutterBottom>Whatsapps: {connections}</Typography>
      <Typography gutterBottom>Pago: Mensual</Typography>
      <Typography gutterBottom>Total: COP:{price.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Typography>
      <Button variant="contained" color="primary" onClick={generatePurchaseCode}>
        Enviar comprobante de pago
      </Button>
    </Grid>
  );
}

export default PaymentDetails;



